import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/layout';
import Blocks from '../components/Blocks';

class JobPostTemplate extends React.Component {
	render() {
		const siteTitle = get(this, 'props.data.site.siteMetadata.title')
		const post = get(this.props, 'data.contentfulJobPost')
		const [page] = get(this, 'props.data.allContentfulJobPost.edges')
		const [menu] = get(this, 'props.data.allContentfulMainMenu.edges')
		const [footer] = get(this, 'props.data.allContentfulFooterMenu.edges')

		return (
			<Layout
				title={post.metaTitle ? post.metaTitle : siteTitle}
				metaDescription={post.metaDescription.metaDescription}
				menuItems={menu.node.menuItems}
				brandLogo={menu.node.brandLogo.file.url}
				location={this.props.location}
				footerItems={footer.node.footerItems}
				linkedIn={footer.node.linkedIn}
				facebook={footer.node.facebook}
				youtube={footer.node.youtube}
				instagram={footer.node.instagram}
				brandLogoFooter={footer.node.brandLogoFooter.file.url}
			>
				<Blocks blocks={page.node.blocks} colorSet={page.node.colorSet} />
			</Layout>
		)
	}
}

export default JobPostTemplate

export const pageQuery = graphql`
	query JobPostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
			}
		}
		contentfulJobPost(slug: { eq: $slug }) {
			title
			metaTitle
			metaDescription {
				metaDescription
			}
		}
		allContentfulMainMenu(filter: {title: {eq: "Hoofdmenu"}}) {
			edges {
				node {
  					...MainMenu
        		}
      		}
		}
		allContentfulFooterMenu(filter: {title: {eq: "Footer"}}) {
			edges {
				node {
  					...Footer
        		}
      		}
		}
		allContentfulJobPost(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					title
					colorSet
					blocks {
						...Blocks
					}
				}
			}
		}
	}
`
